<template>
    <div class="ma-3">
        <v-card>
            <v-card-title class="color-26c6da">Revisão Interna</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field
                            solo
                            prepend-inner-icon="mdi-magnify"
                            label="Pesquisar"
                            v-model="search"
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            :loading="loadingApresentantes"
                            multiple
                            v-model="apresentantesSelected"
                            solo
                            :items="apresentantes"
                            label="Selecione os apresentantes"
                            item-value="cd_apresentante"
                            item-text="cd_apresentante"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-btn @click="doSearch" color="primary">Filtrar</v-btn>
                        <v-btn
                            class="ml-3"
                            :disabled="
                                !search &&
                                    apresentantesSelected.length <= 0 &&
                                    selectedItems.length <= 0
                            "
                            @click="removerFiltros()"
                        >
                            <v-icon class="mr-2">mdi-filter-remove</v-icon>
                            Remover Filtros</v-btn
                        >
                    </v-col>
                    <v-col align="end">
                        <v-btn
                            :disabled="selectedItems.length <= 0"
                            @click="confirmationAction('limpar')"
                            color="yellow"
                            class="text-capitalize"
                            >Limpar RVI</v-btn
                        >
                        <v-btn
                            :disabled="selectedItems.length <= 0"
                            @click="confirmationAction('corrigir')"
                            color="orange"
                            class="ml-2 text-capitalize"
                            >Corrigir</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="my-3">
            <v-card-text>
                <v-data-table
                    v-model="selectedItems"
                    :options.sync="options"
                    show-select
                    item-key="id_titulo"
                    :loading="loading"
                    :headers="headers"
                    :items="revisoes"
                    :search="search"
                    :footer-props="{
                        itemsPerPageOptions: [300, 600, 900],
                        itemsPerPageText: 'Itens por página:'
                    }"
                    :server-items-length="totalResults"
                    class="elevation-1"
                    loading-text="Pesquisando títulos..."
                    no-results-text="Nenhum título encontrado..."
                >
                    <template v-slot:item.cd_status="props">
                        <span color="primary" dark
                            >{{ props.item.cd_status }} -
                            {{ props.item.ds_status }}</span
                        >
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            v-model="confirmationDialog"
            width="650px"
        >
            <div class="text-center" width="420px">
                <v-card>
                    <v-card-title class="headline">Confirmação</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <span v-if="actionSelected === 'limpar'"
                                    >Você tem certeza que quer limpar RVIs dos
                                    titulos selecionados?</span
                                >
                                <span v-else
                                    >Você tem certeza que quer corrigir os
                                    titulos selecionados?</span
                                >
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-data-table
                                    :headers="headersConfirmation"
                                    :items="selectedItems"
                                    :items-per-page="300"
                                    :options.sync="options"
                                    class="elevation-1"
                                    :footer-props="{
                                        itemsPerPageOptions: [300],
                                        itemsPerPageText: 'Itens por página:'
                                    }"
                                ></v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="confirmationDialog = false" color="error"
                            >Cancelar</v-btn
                        >
                        <v-btn @click="action(actionSelected)" color="primary"
                            >Confirmar</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
    </div>
</template>

<script>
import ApresentanteService from '@/services/apresentanteService';
import Vue from 'vue';
export default {
    name: 'RevisaoInterna',
    data() {
        return {
            snackbar: false,
            colorSnackbar: '',
            mensagemSnackbar: '',
            loading: false,
            confirmationDialog: false,
            options: {
                page: 1,
                itemsPerPage: 300
            },
            selectedItems: [],
            loadingApresentantes: false,
            revisoes: [],
            actionSelected: '',
            apresentantes: [],
            apresentantesSelected: [],
            search: '',
            ApresentanteService: new ApresentanteService(Vue.http, this.$store),
            headers: [
                { text: 'Nosso numero', value: 'ds_nosso_numero' },
                { text: 'Id Titulo', value: 'id_titulo' },
                { text: 'Revisão', value: 'cd_revisao_interna' },
                { text: 'Cod. Apresentante', value: 'cd_apresentante' },
                {
                    text: 'Razão social do apresentante',
                    value: 'apresentante_razao_social'
                },
                { text: 'Nome Sacador', value: 'nm_sacador' },
                {
                    text: 'Nome cedente favorecida',
                    value: 'nm_cedente_favorecido'
                },
                { text: 'Tipo endosso', value: 'tp_endosso' },
                { text: 'Status', value: 'cd_status' }
            ],
            headersConfirmation: [
                { text: 'Nosso numero', value: 'ds_nosso_numero' },
                { text: 'Id Titulo', value: 'id_titulo' },
                { text: 'Revisão', value: 'cd_revisao_interna' },
                { text: 'Cod. Apresentante', value: 'cd_apresentante' },
                { text: 'Tipo endosso', value: 'tp_endosso' },
                { text: 'Status', value: 'cd_status' }
            ],
            totalResults: 0
        };
    },
    methods: {
        doSearch() {
            this.getRevisoesInternas();
            this.selectedItems = [];
        },
        removerFiltros() {
            this.search = '';
            this.apresentantesSelected = [];
            this.selectedItems = [];
            this.getRevisoesInternas();
        },
        confirmationAction(action) {
            this.actionSelected = action;
            this.confirmationDialog = true;
        },
        action(action) {
            let data = this.selectedItems.map(item => item.id_titulo);
            this.ApresentanteService.actionRevisaoInterna(action, {
                titulos: data
            })
                .then(() => {
                    this.confirmationDialog = false;
                    this.selectedItems = [];
                    this.getRevisoesInternas();
                    this.snackbar = true;
                    this.colorSnackbar = 'success';
                    this.mensagemSnackbar = 'Ação realizada com sucesso!';
                })
                .catch(error => {
                    this.confirmationDialog = false;
                    this.selectedItems = [];
                    this.snackbar = true;
                    this.colorSnackbar = 'error';
                    this.mensagemSnackbar = 'Erro ao realizar ação!';
                    console.log(error);
                });
        },
        getApresentantes() {
            this.loadingApresentantes = true;
            this.ApresentanteService.getApresentantesRevisaoInterna()
                .then(response => {
                    this.loadingApresentantes = false;
                    this.apresentantes = response;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingApresentantes = false;
                });
        },
        getRevisoesInternas() {
            this.loading = true;
            let params = {
                page: this.options.page,
                per_page: this.options.itemsPerPage
                // nosso_numero: this.options.nosso_numero,
            };
            if (this.apresentantesSelected.length) {
                params.cd_apresentante = this.apresentantesSelected.join(',');
            }
            if (this.search) {
                params.nosso_numero = this.search;
            }
            this.ApresentanteService.getRevisoesInternas(params)
                .then(response => {
                    this.revisoes = response.results;
                    this.totalResults = response.total_results.total;
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.getApresentantes();
        this.getRevisoesInternas();
    },
    watch: {
        options: {
            handler() {
                '';
                this.getRevisoesInternas();
            },
            deep: true
        }
    }
};
</script>

<style scoped>
.color-26c6da {
    color: #26c6da;
    font-size: 2.125rem;
    font-weight: 900;
}
</style>
